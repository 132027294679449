<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t('message.incoming_containers') }}</p>
                <div>
                    <crm-store-update-close
                    :permission="$options.name"
                    :button_type="''"
                    :loading="loadingButton"
                    @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <el-row > 
            <el-col :span="24" v-loading="loadingData">
                <div class="gc-card__body px-3 py-4">
                    <div class="app-form__group mb-0">
                        <div
                            class="
                                gc-card
                                rounded-sm
                                mb-4
                                p-relative
                                mr-3
                                px-3 py-3
                            "
                        >   
                            <div class="mb-4 cargo__right">
                                <select-to-filial
                                    ref="filial" 
                                    v-model="to_filial_id" 
                                    :id="to_filial_id" 
                                    :show_all="0"
                                    :select_only="true"
                                    class="select__width" 
                                    :placeholder="$t('message.filial')"
                                />
                            </div>

                            <div class="mb-5">
                                <el-divider content-position="left"> <h3>{{ $t('message.filial_containers') }}</h3></el-divider>
                                <template>
                                    <el-table
                                        :data="incomingFilialContainers"
                                        stripe
                                        border
                                        style="width: 100%">
                                        <el-table-column type="index" width="50"> </el-table-column>
                                        <el-table-column
                                            prop="id"
                                            :label="$t('message.n')"
                                            width="80">
                                        </el-table-column>

                                        <el-table-column
                                            prop="containerType"
                                            :label="$t('message.name')"
                                            >
                                        </el-table-column>

                                        <el-table-column
                                            prop="products"
                                            :label="$t('message.products')"
                                            >
                                            <template slot-scope="scope">
                                               <span @click="containerProducts(scope.row)" style="cursor:pointer; color:blue">{{ $t('message.products') }}</span> 
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="barcode"  
                                            :label="$t('message.barcode')"
                                           >
                                        </el-table-column>

                                        <el-table-column
                                            prop="country"
                                            :label="$t('message.country')"
                                            >
                                            <template slot-scope="scope">
                                                {{ scope.row.country ? scope.row.country.name : '' }}
                                            </template>
                                        </el-table-column>
                                        
                                        <el-table-column
                                            prop="deals"
                                            :label="$t('message.deals')"
                                            width="180">
                                            <template slot-scope="scope">
                                                <span v-for="(deal, key) in scope.row.deals" :key="'deal'+deal"> {{ deal }} <span v-if="key+1 != scope.row.deals.length"> , </span> </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="total_weight"
                                            width="180"
                                            :label="$t('message.weight_in_kg')">
                                        </el-table-column>

                                        <el-table-column
                                            width="180"
                                            :label="$t('message.inventarization')">
                                            <template slot-scope="scope">
                                                <el-button @click="inventarizationProducts(scope.row)" size="medium" type="primary" plain v-if="permissions.some(per => per.slug == 'containers.storeContainerProductsInFilial')">{{ $t('message.inventarization') }}</el-button>
                                            </template>
                                        </el-table-column>

                                    </el-table>
                                </template>
                            </div>
                            <div class="pt-5">
                                <el-divider content-position="left"><h3>{{ $t('message.new_containers') }}</h3></el-divider>
                                <div class="mb-4 cargo__right">
                                    <el-button class="mr-5" @click="containerInventarization()" size="medium" type="primary" plain v-if="permissions.some(per => per.slug == 'containers.setContainersInFilial')">{{ $t('message.container_inventorization') }}</el-button>
                                </div>
                                <template>
                                    <el-table
                                        :data="storeContainer"
                                        stripe
                                        border
                                        style="width: 100%"
                                        @selection-change="handleSelectionChange">
                                        <el-table-column type="index" width="50"> </el-table-column>
                                        <el-table-column v-if="!scan"
                                            type="selection"
                                            width="55">
                                        </el-table-column>
                                        <el-table-column
                                            prop="id"
                                            :label="$t('message.n')"
                                            width="80">
                                        </el-table-column>

                                        <el-table-column
                                            prop="containerType"
                                            :label="$t('message.name')"
                                            >
                                        </el-table-column>

                                        <el-table-column
                                            prop="products"
                                            :label="$t('message.products')"
                                            >
                                            <template slot-scope="scope">
                                               <span @click="containerProducts(scope.row)" style="cursor:pointer; color:blue">{{ $t('message.products') }}</span> 
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="barcode"  
                                            :label="$t('message.barcode')"
                                           >
                                        </el-table-column>

                                        <el-table-column
                                            prop="country"
                                            :label="$t('message.country')"
                                            >
                                            <template slot-scope="scope">
                                                {{ scope.row.country ? scope.row.country.name : '' }}
                                            </template>
                                        </el-table-column>
                                        
                                        <el-table-column
                                            prop="deals"
                                            :label="$t('message.deals')"
                                            width="180">
                                            <template slot-scope="scope">
                                                <span v-for="(deal, key) in scope.row.deals" :key="'deal'+deal"> {{ deal }} <span v-if="key+1 != scope.row.deals.length"> , </span> </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="total_weight"
                                            width="180"
                                            :label="$t('message.weight_in_kg')">
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </div>
                            <div class="mt-5 cargo__right">
                                <!-- <el-select v-if="!scan" v-model="container_select" :placeholder="$t('message.container')" class="w-100">
                                    <el-option
                                        v-for="item in list"
                                        :key="item.id"
                                        :label="item.id +' - '+item.containerType+' >'+$t('message.code')+': '+item.barcode"
                                        :value="item.id">
                                    </el-option>
                                </el-select> -->
                                <el-input v-if="scan" class="mr-5" ref="scanRef" @input="scanContainerBarcodeDebounce" :placeholder="$t('message.scan')" v-model="barcode_search" size="mini"></el-input>
                                <el-switch
                                    v-model="scan"
                                    :active-text="$t('message.scan')"
                                    :inactive-text="$t('message.select')">
                                </el-switch>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer class="bg-se" :with-header="false"
                :visible.sync="createModal"
                ref="productInventorization"
                :append-to-body="true"
                @opened="drawerOpened('productInventorizationChild')"
                @closed="drawerClosed('productInventorizationChild')" 
                size="85%">
                <crm-inventory
                    @refreshFilialContainers="fetchData"
                    :container="containerRow"
                    ref="productInventorizationChild"
                    drawer="productInventorization"
                />
            </el-drawer>

            <el-drawer class="bg-se" :with-header="false"
                :visible.sync="productDrawer"
                ref="productDrawer"
                :append-to-body="true"
                @opened="drawerOpened('productDrawerChild')"
                @closed="drawerClosed('productDrawerChild')" 
                size="70%">
                <crm-products 
                    :container="containerRow"
                    ref="productDrawerChild"
                    drawer="productDrawer"
                />
            </el-drawer>

        </div>
    </div>
</template>

<script>
import selectBoard from "@/components/inventory/select-deal-board";
import SelectCargo from "@/components/inventory/select-cargo";
import CrmInventory from "./crm-inventory.vue";
import CrmProducts from "./crm-products.vue";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import child_list from "@/utils/mixins/child_list";
import { successVoice, errorVoice, open } from '@/utils/audioHelper';

export default {
    mixins: [form, drawer, child_list],
    components: {
        selectBoard,
        SelectCargo,
        CrmInventory,
        CrmProducts,
        selectFromFilial,
        selectToFilial,

    },
    data: () => ({
        mode: true,
        createModal: false,
        container_select: null,
        list: [],
        storeContainer: [],
        containerRow: {},
        selectedContainers: [],
        productDrawer: false,
        to_filial_id: null,
        scan: true,
        barcode_search: '',
    }),

    watch: {
        container_select: {
            handler: async function(newVal, oldVal) {
                if(newVal){
                    let selected = this.list.find(el => el.id === newVal);
                    if(!this.storeContainer.some(el => el.id === newVal)){
                        this.storeContainer.push(selected);
                    }
                }
            },
            deep: true,
            immediate: true
        },
        scan: {
            handler: async function(newVal, oldVal) {   
                if(this.to_filial_id && !newVal){
                    this.fetchData();
                }
            },
            deep: true,
            immediate: true
        },

        to_filial_id: {
            handler: async function(newVal, oldVal) {
                this.fetchData();
                if(this.$refs['scanRef']){
                    this.$refs['scanRef'].focus(); 
                }
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            authUser: "auth/user",
            permissions: "auth/permissions",
            rules: "containers/rules",
            model: "containers/model",
            columns: "dealProducts/columns",
            readyToFlight: "containers/readyToFlight",
            incomingFilialContainers: "containers/incomingFilialContainers",
        }),
    },
    methods: {
        ...mapActions({
            empty: "containers/empty",
            readyToSendToFlight: "containers/readyToSendToFlight",
            setContainersInFilial: "containers/setContainersInFilial",
            showIncomingFilialContainers: "containers/showIncomingFilialContainers",
            scanContainer: "containers/scanContainer",
        }),
        afterOpen(){
            this.fetchData();
        },  
        stringContainString(arr) {
            return arr[1].split('').every(function(letter) {
                return arr[0].indexOf(letter) != -1;
            });
        },
        listChanged() {
            this.parent().listChanged();
        },
        scanContainerBarcodeDebounce: _.debounce(function(val) {
            this.scanContainerBarcode(val);
        }, 300),
        scanContainerBarcode(val){
            let existing_container = this.incomingFilialContainers.find(el => el.barcode === val)
            if(existing_container){
                this.inventarizationProducts(existing_container);
                this.barcode_search = '';
            }else{
                let upper_barcode = val.toUpperCase();
                if(upper_barcode && this.stringContainString([upper_barcode, 'C-F'])){
                    this.scanContainer({search: upper_barcode, recievable: true})
                        .then(res => {
                            if(res.data.result.success){
                                let container = JSON.parse(JSON.stringify(res.data.result.data))
                                if(this.storeContainer.filter(el => el.id === container.id).length === 0){
                                    successVoice();
                                    this.storeContainer.push(container);
                                }else{
                                    errorVoice();
                                    this.$notify({
                                        title: this.$t('message.container'),
                                        type: "error",
                                        offset: 130,
                                        message: this.$t('message.container_already_exists')
                                    });
                                }
                                this.barcode_search = '';
                            }else{
                                errorVoice();
                                this.$error_message(res);
                                this.barcode_search = '';
                            }
                        }).catch(err => {
                            errorVoice();
                        });
                }
            }
        },
        fetchData(){
            this.list = [];
            this.storeContainer = [];
            this.loadingData = true;
            this.loadAllData()
                .then(() => { this.loadingData = false })
                .catch(err => { this.loadingData = false });
        },
        async loadAllData(){
            if(this.to_filial_id){
                await this.showIncomingFilialContainers({to_filial_id: this.to_filial_id})
                    .then(res => {
                    }).catch(err => {
                    });
                if(!this.scan){
                    await this.readyToSendToFlight({recievable: true, to_filial_id: this.to_filial_id}).then(res => {
                        this.storeContainer = JSON.parse(JSON.stringify(this.readyToFlight));
                    });
                }
            }
        },
        handleSelectionChange(val) {
            this.selectedContainers = val;
        },
        containerInventarization(){
            let selected = [];
            if(this.scan){
                selected = this.storeContainer.map(el => el.id);
            }else{
                selected = this.selectedContainers.map(el => el.id);
            }
            if(selected.length > 0 ){
                this.loadingButton = true;
                this.setContainersInFilial({to_filial_id: this.to_filial_id, containers: selected})
                    .then(async res => {
                        await this.fetchData();
                        this.loadingButton = false;
                    }).catch(err => {
                        this.loadingButton = false;
                    });
            }else{
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.select container to store')
                });
            }
        },
        containerProducts(row) {
            this.containerRow = row;
            this.productDrawer = true
        },
        inventarizationProducts(row) {
            open();
            this.containerRow = row;
            this.createModal = true
        },
        afterLeave(){
            if(this.$refs['filial'] && this.$refs['filial'].text){
                this.$refs['filial'].text = '';
            }
            this.list = [];
            this.storeContainer = [];
            this.containerRow = {};
            this.to_filial_id = null;
            this.container_select = null;
            this.empty();
        }, 
    }

}
</script>

<style>

</style>
